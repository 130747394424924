.book-list-item {
  width: 133px;
  height: 192px;
  float: left;
  overflow: hidden;
  position: relative;
}
.book-item-cover {
  width: 105px;
  height: 137px;
  opacity: 1;
  margin: 15px 15px 5px 15px;
  cursor: pointer;
  overflow: hidden;
}
.book-item-title {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 5px;
  height: 33px;
  font-size: 15px;
  line-height: 17px;
  opacity: 1;
  text-align: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  position: relative;
}
.book-item-config {
  width: 105px;
  height: 35px;
  position: relative;
  bottom: 71px;
  left: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  opacity: 1;
  cursor: pointer;
  animation: fade-in 0.1s ease-in 0s 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.book-more-action {
  position: absolute;
  top: 23px;
  right: 17px;
  font-size: 18px;
  cursor: pointer;
  animation: slide-down-rotate 0.1s ease-in-out 0s 1;
}
.reading-progress-icon {
  position: absolute;
  top: 18px;
  left: 17px;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 31px;
  cursor: pointer;
  transform: scale(0.9);
  animation: slide-down 0.1s ease-in-out 0s 1;
}
@keyframes slide-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-down-rotate {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.reading-percentage-char {
  display: inline-block;
  transform: scale(0.5) translate(-5px, 2px);
}
.book-love-icon {
  position: absolute;
  bottom: 45px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  animation: slide-up 0.1s ease-in-out 0s 1;
}
.book-loved-icon {
  position: absolute;
  bottom: 44px;
  right: 19px;
  font-size: 20px;
  color: #f87356;
  cursor: pointer;
  z-index: 2;
}
.book-selected-icon {
  position: absolute;
  bottom: 44px;
  right: 19px;
  font-size: 20px;
  color: #f87356;
  cursor: pointer;
  z-index: 2;
}

.action-dialog-parent {
  position: fixed;
  top: 0px;
  left: 200px;
  z-index: 10;
}
