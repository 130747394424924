.card-list-container-parent {
  position: absolute;
  top: 75px;
  left: 200px;
  width: calc(100% - 200px);
  height: calc(100% - 75px);
  overflow: hidden;
}
.card-list-container {
  width: 100%;
  height: calc(100% - 38px);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  user-select: text;
}

.card-page-item-date {
  /* float: left; */
  position: absolute;
  font-size: 18px;
  line-height: 25px;
  opacity: 0.75;
  margin-left: 19px;
  height: 20px;
}
.card-page-item {
  /* display: block; */
  width: 100%;

  /* height: 400px; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  /* font-size: 0px; */
  /* clear: both; */
  /* float: left; */
  /* zoom: 1; */

  overflow: hidden;
  clear: both;
}
.card-list-item {
  float: left;
  /* display: inline-block; */
  min-width: 330px;
  cursor: pointer;
  font-size: 15px;
  width: calc(50vw - 135px);
  height: 176px;
  opacity: 1;
  border: 15px solid transparent;
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  position: relative;
  bottom: 10px;
}
.card-list-item-card {
  height: 100%;
  border-radius: 5px;
}
.card-list-item-chapter {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 18px;
  line-height: 18px;
  font-size: 15px;
  max-width: 130px;
}
.card-list-item-citation {
  font-size: 15px;
  position: absolute;
  bottom: 30px;
  right: 5px;
  margin: 5px;
  white-space: nowrap;
  white-space: normal;
  word-break: break-all;
}
.card-list-item-text-parent {
  position: absolute;
  width: 100%;
  height: 104px;
}
.card-list-item-text-note {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 104px;
  opacity: 0.6;
}
.card-list-item-text {
  width: calc(100% - 25px);
  height: 53px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 14px 10px 0px 10px;
  position: absolute;
  cursor: text;
  line-height: 18px;
  font-size: 15px;
  padding-right: 5px;
}
.card-list-item-note {
  width: calc(100% - 25px);
  height: 73px;
  overflow-y: scroll;
  margin: 14px 10px 0px 10px;
  position: absolute;
  cursor: text;
  line-height: 18px;
  font-size: 15px;
  padding-right: 5px;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 7px;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  transition: all 0.2s;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.card-list-item-title {
  float: left;
  /* width: 100px; */
}
.card-list-container-box {
  margin-top: 40px;
  height: calc(100% - 89px);
  width: 100%;
}
.icon-card-right {
  margin-left: 5px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.card-list-item-show-more {
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  text-align: center;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}
.card-list-show-more-icon {
  /* display: block; */
  font-size: 13px;
  line-height: 18px;
  opacity: 1;
  text-align: center;
  position: absolute;
  bottom: 5px;
  margin-left: calc(50% - 6px);
}
